import { useCallback } from 'react';

import { ApplicationPage } from 'app/types';
import { Page } from 'common/components/page';
import { PageHeader } from 'common/components/page-header';
import { DEFAULT_AUTHORIZED_PAGE_PROPS } from 'common/components/page/config';

import { Cont404 } from 'common/containers/404';
import { useStoreConfig } from 'common/providers/config';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { Checkout } from 'domains/checkout/containers/checkout';

const TITLE = 'Place Order';

const CheckoutPage: ApplicationPage = () => {
  const navigate = useRouterNavigate();

  const toCart = useCallback(() => {
    void navigate(Routes.Cart);
  }, [navigate]);

  const { isOnlineOrderingDisabled } = useStoreConfig();

  if (isOnlineOrderingDisabled) {
    return <Cont404 />;
  }

  return (
    <Page
      {...DEFAULT_AUTHORIZED_PAGE_PROPS}
      authorized={false}
    >
      <PageHeader
        title={TITLE}
        onClickBack={toCart}
      />
      <Checkout />
    </Page>
  );
};

CheckoutPage.getMeta = () => {
  return {
    title: TITLE,
  };
};

CheckoutPage.getMainLayoutProps = () => ({
  subheader: null,
});

export default CheckoutPage;
