import { useBreakpoint } from '@swe/shared/tools/media';

import { withFormField } from '@swe/shared/ui-kit/components/form/core';
import { RadioGroup } from '@swe/shared/ui-kit/components/form/radio-group';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import { Grid } from '@swe/shared/ui-kit/components/grid';
import { Text } from '@swe/shared/ui-kit/components/text';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { FullShopFulfillmentType, ShopFulfillmentType } from 'entities/shop/info';

export type FulfillmentProps = {
  orderType: `${ShopFulfillmentType}`;
  shopName?: string;
  shopAddress?: string;
  options?: { value: FullShopFulfillmentType; label: string }[];
  deliveryAddress?: string;
  deliveryAddressSecondLine?: string;
} & FormControl<FullShopFulfillmentType> &
  ComponentHasClassName;

const Fulfillment = ({
  className,
  onChange,
  shopName,
  shopAddress,
  orderType,
  options,
  deliveryAddress,
  value,
  onBlur,
  disabled,
  deliveryAddressSecondLine,
}: FulfillmentProps) => {
  const { mobile } = useBreakpoint();

  const _onChange = useCallback(
    (value: FullShopFulfillmentType | null) => {
      if (value) {
        onBlur?.();
        onChange?.(value);
      }
    },
    [onBlur, onChange],
  );

  if (orderType === 'Pickup') {
    return (
      <Grid.Row
        vAlign="center"
        className={className}
        spacing="xxs"
      >
        {shopName && (
          <Grid.Cell cols="content">
            <Text
              variant="body"
              size="lg"
              adaptive
            >
              {shopName}
            </Text>
          </Grid.Cell>
        )}
        {shopAddress && (
          <Grid.Cell
            className={styles.address}
            cols="content"
          >
            <Text
              adaptive
              variant="body"
              size="lg"
            >
              {shopAddress}
            </Text>
          </Grid.Cell>
        )}
        {!!(options && options.length) && (
          <Grid.Cell cols={12}>
            <RadioGroup
              name="fulfillment"
              variant="radio"
              inline
              spacing={mobile ? 'sm' : 'xxl'}
              value={value}
              options={options}
              onChange={_onChange}
              disabled={disabled}
            />
          </Grid.Cell>
        )}
      </Grid.Row>
    );
  }
  return (
    <div className={className}>
      {!!deliveryAddress && (
        <Text
          variant="body"
          size="lg"
          adaptive
        >
          {deliveryAddress}
        </Text>
      )}
      {!!deliveryAddressSecondLine && (
        <Text
          variant="body"
          size="lg"
          adaptive
        >
          {deliveryAddressSecondLine}
        </Text>
      )}
    </div>
  );
};

const FormFulfillment = withFormField<FullShopFulfillmentType, FulfillmentProps>(Fulfillment, { passRef: false });

export { Fulfillment, FormFulfillment };
export default FormFulfillment;
