import { Stack } from '@swe/shared/ui-kit/components/stack';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { Section, SectionProps } from 'domains/checkout/components/section';

export type SectionListProps = ComponentHasClassName & {
  list: Pick<
    SectionProps,
    'title' | 'subHeading' | 'children' | 'bullet' | 'headerButton' | 'headerAction' | 'headerColor'
  >[];
  divider?: boolean;
  size?: SectionProps['size'];
};

const SectionList = ({ list, size = 'md', divider, className }: SectionListProps) => {
  return (
    <Stack
      divider={divider}
      spacing={size === 'md' ? 'xxs' : 'sm'}
      className={className}
    >
      {list.map(({ children, title, ...restProps }) => (
        <Section
          key={title}
          {...restProps}
          title={title}
          size={size}
        >
          {children}
        </Section>
      ))}
    </Stack>
  );
};

export { SectionList };
export default SectionList;
