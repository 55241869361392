import { SectionHeading, SectionHeadingProps } from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';
import cx from 'clsx';
import { ReactNode } from 'react';

type BaseSectionProps = {
  className?: string;
  title?: string;
  subHeading?: string;
  children?: ReactNode;
  bullet?: SectionHeadingProps['bullet'];
  headerColor?: SectionHeadingProps['color'];
  headerButton?: SectionHeadingProps['button'];
  headerAction?: SectionHeadingProps['action'];
  size?: SectionHeadingProps['size'];
};

type SectionProps = BaseSectionProps;

const Section = ({
  className,
  title,
  subHeading,
  bullet,
  headerButton,
  headerAction,
  size,
  headerColor,
  children,
}: SectionProps) => {
  return (
    <Stack
      className={cx(className)}
      spacing="none"
    >
      <SectionHeading
        subHeading={subHeading}
        bullet={bullet}
        size={size}
        button={headerButton}
        action={headerAction}
        color={headerColor}
      >
        {title}
      </SectionHeading>
      {children}
    </Stack>
  );
};

export type { SectionProps };
export { Section };
export default Section;
