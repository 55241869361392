import { Alert } from '@swe/shared/ui-kit/components/alert';
import { Stack } from '@swe/shared/ui-kit/components/stack';

import React, { ReactNode } from 'react';

import styles from './styles.module.scss';

import { Receipt, ReceiptProps } from 'domains/checkout/components/receipt';

export type SummaryProps = {
  promoCodes: ReactNode;
  loyalty: ReactNode;
  footer: ReactNode;
  receipt: ReceiptProps;
  alertMessage: string;
};

const Summary = ({ footer, promoCodes, receipt, loyalty, alertMessage }: SummaryProps) => {
  return (
    <Stack
      className={styles.root}
      spacing="md"
    >
      <Stack
        divider
        spacing="md"
      >
        {loyalty}
        {promoCodes}
        <Receipt
          className={styles.receipt}
          {...receipt}
        />
      </Stack>
      {alertMessage && (
        <Alert
          icon={false}
          color="danger"
        >
          {alertMessage}
        </Alert>
      )}
      {footer}
    </Stack>
  );
};

export { Summary };
export default Summary;
