import { Trans } from '@swe/shared/tools/intl/components/trans';
import { withFormField } from '@swe/shared/ui-kit/components/form/core';
import { Toggle } from '@swe/shared/ui-kit/components/form/toggle';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import Grid from '@swe/shared/ui-kit/components/grid';
import Stack from '@swe/shared/ui-kit/components/stack';
import { Tag } from '@swe/shared/ui-kit/components/tag';
import Text from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { formatPoints } from '@swe/shared/utils/points';
import { formatPrice } from '@swe/shared/utils/price';
import cl from 'clsx';

import { useCallback, useEffect, useMemo, useState } from 'react';

import { Slider } from './components/slider';
import styles from './styles.module.scss';

import { Requirement } from 'entities/loyalty/info';
import { PointsRedemptionType } from 'entities/loyalty/settings';

type AnyNumberOfPoints = {
  type: PointsRedemptionType.AnyNumberOfPoints;
  notificationFn: () => void;
};

type PresetOptions = {
  type: PointsRedemptionType.PresetOptions;
  presets: number[];
};

type MaximumAvailable = {
  type: PointsRedemptionType.MaximumAvailable;
};

type LoyaltyProps = {
  type: PointsRedemptionType;
  available: number;
  ratio?: number;
  maxPointToRedeem: number;
  exceedMaxNotify?(): void;
  requirements: Requirement[];
  pending?: boolean;
} & (AnyNumberOfPoints | PresetOptions | MaximumAvailable) &
  FormControl<number> &
  ComponentHasClassName;

const Loyalty = ({
  exceedMaxNotify,
  available,
  onChange,
  value,
  onBlur,
  ratio = 1,
  disabled,
  requirements,
  pending,
  ...props
}: LoyaltyProps) => {
  const [isShownLoyaltyBlock, setIsShownLoyaltyBlock] = useState(false);

  const toggle = useCallback(
    (value: boolean) => {
      setIsShownLoyaltyBlock(value);
      if (!value) {
        onChange?.(0);
        onBlur?.();
      } else if (props.type !== PointsRedemptionType.PresetOptions) {
        onChange?.(props.maxPointToRedeem);
        onBlur?.();
      }
    },
    [onBlur, onChange, props],
  );

  useEffect(() => {
    if (!isShownLoyaltyBlock) {
      setIsShownLoyaltyBlock(value > 0);
    }
  }, [isShownLoyaltyBlock, value]);

  const [highlightAvailable, setHighlightAvailable] = useState(false);

  const sliderHandler = useCallback(
    (value: number) => {
      if (props.type !== PointsRedemptionType.AnyNumberOfPoints) {
        return;
      }
      setHighlightAvailable(false);
      onChange?.(value);
    },
    [onChange, props],
  );

  const exceedMaxFn = useCallback(() => {
    setHighlightAvailable(true);
    exceedMaxNotify?.();
  }, [exceedMaxNotify]);

  const hasLimit = available > props.maxPointToRedeem;

  const layoutSlider = useMemo(() => {
    if (props.type !== PointsRedemptionType.AnyNumberOfPoints) {
      return;
    }
    return (
      <Stack className={styles.content}>
        <Slider
          value={value}
          name="anyNumberOfPoints"
          max={props.maxPointToRedeem}
          maxMsg={hasLimit ? `Max ${props.maxPointToRedeem} pts` : undefined}
          onChange={sliderHandler}
          exceedMaxFn={exceedMaxFn}
          step={0.01}
        />
        {hasLimit && (
          <Text>
            <Trans
              id="domains.checkout.form.loyalty.maxPointsWarning"
              defaultMessage="Your loyalty points will not cover the total of your order. Amount of loyalty points has been adjusted to the maximum points allowed."
            />
          </Text>
        )}
      </Stack>
    );
  }, [exceedMaxFn, hasLimit, props, sliderHandler, value]);

  const layoutMax = useMemo(() => {
    const text = hasLimit ? (
      <Trans
        id="domains.checkout.form.loyalty.maxPointsWarning"
        defaultMessage="Your loyalty points will not cover the total of your order. Amount of loyalty points has been adjusted to the maximum points allowed."
      />
    ) : (
      'All available points are applied'
    );
    return (
      <Text
        size="lg"
        mt="xs"
      >
        {text}
      </Text>
    );
  }, [hasLimit]);

  const preparedPresets = useMemo(() => {
    if (props.type !== PointsRedemptionType.PresetOptions) {
      return;
    }
    const { presets } = props;
    const sorted = presets.sort((a: number, b: number) => a - b);
    const availablePresets = sorted.filter((preset) => preset < available);
    return { sorted, available: availablePresets };
  }, [available, props]);

  const layoutPresets = useMemo(() => {
    if (preparedPresets?.available.length === 0 && preparedPresets.sorted[0]) {
      return (
        <Text
          mt="xs"
          size="lg"
        >
          Sorry, you must have at least {preparedPresets.sorted[0]} points to redeem on an order
        </Text>
      );
    }

    return (
      <Stack
        mt="xs"
        direction="row"
        wrap
        spacing="xxs"
      >
        {preparedPresets?.available.map((presetValue) => (
          <Tag
            name="preset"
            key={presetValue}
            active={presetValue === value}
            onToggle={() => onChange?.(presetValue)}
          >
            {formatPoints(presetValue)} ({formatPrice(presetValue * ratio)})
          </Tag>
        ))}
      </Stack>
    );
  }, [onChange, preparedPresets?.available, preparedPresets?.sorted, ratio, value]);

  const isDisabledPresets = props.type === PointsRedemptionType.PresetOptions && !preparedPresets?.available.length;

  const content = useMemo(() => {
    if (!isShownLoyaltyBlock && !isDisabledPresets) {
      return null;
    }
    switch (props.type) {
      case PointsRedemptionType.AnyNumberOfPoints: {
        return layoutSlider;
      }
      case PointsRedemptionType.PresetOptions: {
        return layoutPresets;
      }
      case PointsRedemptionType.MaximumAvailable: {
        return layoutMax;
      }
    }
  }, [isShownLoyaltyBlock, isDisabledPresets, props.type, layoutSlider, layoutPresets, layoutMax]);

  let msg = null;

  if (requirements.includes(Requirement.NotificationSubscription)) {
    msg = (
      <Trans
        id="domains.checkout.form.loyalty.requirementsWarning.notificationsSubscription"
        defaultMessage="Subscribe to marketing notifications in your profile to use the Loyalty Program"
      />
    );
    if (requirements.includes(Requirement.Phone)) {
      msg = (
        <Trans
          id="domains.checkout.form.loyalty.requirementsWarning.phoneNumberAndNotificationsSubscription"
          defaultMessage="Provide your phone number and subscribe to marketing notifications in your profile to use the Loyalty Program"
        />
      );
    }
  } else if (requirements.includes(Requirement.Phone)) {
    msg = (
      <Trans
        id="domains.checkout.form.loyalty.requirementsWarning.phoneNumber"
        defaultMessage="Provide your phone number in your profile to use the Loyalty Program"
      />
    );
  }

  const isDisabled = (!isShownLoyaltyBlock && pending) || disabled || isDisabledPresets || available === 0;

  return (
    <Grid.Row
      spacing="xxs"
      vAlign="center"
    >
      <Grid.Cell cols="content">
        <Toggle
          label={
            <Trans
              id="domains.checkout.form.loyalty.label"
              defaultMessage="Use Loyalty"
            />
          }
          name="loyalty"
          value={isShownLoyaltyBlock}
          onChange={toggle}
          disabled={isDisabled}
        />
      </Grid.Cell>
      <Grid.Cell cols="content">
        <Text
          variant="control"
          size="md"
          className={cl(styles.loyalty, highlightAvailable && styles.highlightAvailable)}
        >
          {available > 0
            ? `Available ${formatPoints(available)} (${formatPrice(available * ratio)})`
            : 'No points available yet'}
        </Text>
      </Grid.Cell>
      {disabled && msg && (
        <Grid.Cell cols={12}>
          <Text
            size="lg"
            mt="xs"
          >
            {msg}
          </Text>
        </Grid.Cell>
      )}
      {content}
    </Grid.Row>
  );
};

const FormLoyalty = withFormField<number, LoyaltyProps>(Loyalty, { passRef: false });

export type { LoyaltyProps };
export { Loyalty, FormLoyalty };
export default FormLoyalty;
