import { useKeyPress } from '@swe/shared/hooks/use-key-press';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Box } from '@swe/shared/ui-kit/components/box';
import { Button } from '@swe/shared/ui-kit/components/button';
import { Chips } from '@swe/shared/ui-kit/components/chips';
import { withFormField } from '@swe/shared/ui-kit/components/form/core';
import { Input } from '@swe/shared/ui-kit/components/form/input';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';
import { Grid } from '@swe/shared/ui-kit/components/grid';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import { Text } from '@swe/shared/ui-kit/components/text';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';

import styles from './styles.module.scss';

type PromoCodesProps = FormControl<string[]> & { link?: ReactNode; pending: boolean; title: string };

const PromoCodes = ({ onChange, value, link, error, disabled, pending, required, title }: PromoCodesProps) => {
  const { mobile } = useBreakpoint();
  const [inputValue, setInputValue] = useState('');
  const [localErrors, setLocalErrors] = useState<string[]>([]);

  const addCode = useCallback(() => {
    if (!inputValue.length) {
      return;
    }
    if (value.includes(inputValue)) {
      setLocalErrors(['The code was already used']);
      return;
    }
    onChange?.([...value, inputValue]);
    setInputValue('');
  }, [inputValue, value, onChange]);

  const removeCode = useCallback(
    (code: string) => {
      const data = value.filter((_code) => _code !== code);
      onChange?.(data);
    },
    [value, onChange],
  );

  const k = useKeyPress({
    onKeyDown: addCode,
    prevent: true,
    key: 'Enter',
  });

  const allErrors = useMemo(() => {
    return [...(Array.isArray(error) ? error : []), ...localErrors].join('; ');
  }, [error, localErrors]);

  const _onChange = useCallback((code: string) => {
    setLocalErrors([]);
    setInputValue(code);
  }, []);

  const prevValue = useRef(value);
  const valuePrepared = useMemo(() => {
    if (pending) {
      return prevValue.current;
    }
    prevValue.current = value;
    return value;
  }, [pending, value]);

  return (
    <Grid.Row spacing="xxxs">
      <Grid.Cell cols={12}>
        <Grid.Row
          wrap={false}
          hAlign="between"
        >
          <Grid.Cell cols="auto">
            <Text
              variant="control"
              size="xl"
            >
              {title}
            </Text>
          </Grid.Cell>
          <Grid.Cell cols="content">{link}</Grid.Cell>
        </Grid.Row>
      </Grid.Cell>
      <Grid.Cell cols={12}>
        <Grid.Row
          vAlign="start"
          spacing="xs"
        >
          <Grid.Cell cols="auto">
            <Input
              size={mobile ? 'md' : 'lg'}
              name="code"
              value={inputValue}
              onChange={_onChange}
              staticNote={false}
              {...k}
              ariaLabel="Code"
              error={allErrors}
              disabled={disabled}
              maxLength={50}
              required={required}
            />
          </Grid.Cell>
          <Grid.Cell cols="content">
            <Box mt="xxs">
              <Button
                type="button"
                color="light"
                onClick={addCode}
                pending={pending}
                name="apply"
              >
                Apply
              </Button>
            </Box>
          </Grid.Cell>
          {!!value.length && (
            <Grid.Cell cols={12}>
              <Stack
                direction="row"
                spacing="xxs"
                wrap
              >
                {valuePrepared.map((code, index) => {
                  return (
                    <Chips
                      key={`${index}${code}`}
                      disabled={pending}
                      onClose={() => removeCode(code)}
                    >
                      <div className={styles.chips}>{code}</div>
                    </Chips>
                  );
                })}
              </Stack>
            </Grid.Cell>
          )}
        </Grid.Row>
      </Grid.Cell>
    </Grid.Row>
  );
};

const FormPromoCodes = withFormField<string[], PromoCodesProps>(PromoCodes, { passRef: false });

export { PromoCodes, FormPromoCodes };
export default FormPromoCodes;
