import { useResize } from '@swe/shared/hooks';
import { useDrag, Handler } from '@swe/shared/hooks/use-gestures';
import { Input } from '@swe/shared/ui-kit/components/form/input';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { Text } from '@swe/shared/ui-kit/components/text';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';
import { debounce } from '@swe/shared/utils/func';

import { isKeyPressed } from '@swe/shared/utils/keyboard';
import { round } from '@swe/shared/utils/number';
import { isEqual } from '@swe/shared/utils/object';

import cn from 'clsx';
import { useCallback, useEffect, useRef, useState, KeyboardEvent, useMemo, forwardRef } from 'react';

import styles from './styles.module.scss';

type Value = number;

export type SliderProps = {
  label?: string | boolean;
  step?: number;
  max: number;
  maxMsg?: string;
  exceedMaxFn?: () => void;
} & FormControl<Value> &
  ComponentHasClassName;

const Slider = forwardRef<FormControlRef, SliderProps>(
  ({ exceedMaxFn, max, maxMsg, onChange, value, className, label, step = 1 }) => {
    const pointerRef = useRef<HTMLDivElement>(null);
    const trackRef = useRef<HTMLDivElement>(null);
    const scaleRef = useRef<HTMLDivElement>(null);

    const [innerValue, setInnerValue] = useState(value);
    const [width, setWidth] = useState<number | null>(null);
    const calcWidth = useCallback(() => {
      if (trackRef.current && trackRef.current.offsetParent !== null) {
        setWidth(trackRef.current.offsetWidth);
      }
    }, []);

    useResize(calcWidth);
    useEffect(calcWidth, [calcWidth]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onChangeDebounced = useCallback(debounce(onChange || (() => {}), 500), [onChange]);

    const localMin = 0;

    const scale = max - localMin;

    const steps: number[] = Array(Math.round(scale / step))
      .fill(1)
      .map((_, index) => index * step + localMin);

    steps.push(max);

    const findNearest = useCallback(
      (value: number) => {
        for (let i = 0; i < steps.length; i += 1) {
          if (value >= steps[i] && value <= steps[i + 1]) {
            if (value >= steps[i] + step / 2) {
              return steps[i + 1];
            }
            return steps[i];
          }
        }
        if (value <= steps[0]) {
          return steps[0];
        }
        return steps[steps.length - 1];
      },
      [step, steps],
    );

    const [pointerState, setPointerState] = useState(innerValue);

    useEffect(() => {
      setPointerState(findNearest(innerValue));
    }, [findNearest, innerValue]);

    const trimByLimits = useCallback((scaleValue: number) => Math.max(Math.min(scaleValue, scale), 0), [scale]);

    const countStepDecimals = useMemo(() => `${step}`.split('.')[1]?.length || 0, [step]);

    const getNormalizedValue = useCallback(
      (scaleValue: number) => {
        const nearest = findNearest(trimByLimits(scaleValue) + localMin);
        return round(nearest, countStepDecimals);
      },
      [findNearest, countStepDecimals, trimByLimits],
    );

    const localOnChange = useCallback(
      (scaleValue: number) => {
        const normalizedValue = getNormalizedValue(scaleValue);
        setInnerValue(normalizedValue);
      },
      [getNormalizedValue, setInnerValue],
    );

    const [dragging, setDragging] = useState(false);
    const handleChange = useCallback(
      (_scaleValue: number, noDebounce = false) => {
        const values = getNormalizedValue(_scaleValue);
        if (noDebounce) {
          onChange?.(values);
        } else {
          onChangeDebounced(values);
        }
      },
      [getNormalizedValue, onChangeDebounced, onChange],
    );

    const cb = useCallback<() => Handler<'drag'>>(() => {
      return ({ dragging, movement: [x], memo, last, first }) => {
        let prevValue = memo;

        if (first && width === null) {
          calcWidth();
        }

        if (width === null) {
          return;
        }

        if (prevValue === undefined) {
          prevValue = (pointerState / scale) * width;
        }

        const scaleValue = trimByLimits(((x + prevValue) / width) * scale);

        if (dragging) {
          localOnChange(scaleValue);
          setDragging(true);
        }

        if (last) {
          handleChange(scaleValue);
          setDragging(false);
        }
        return prevValue;
      };
    }, [calcWidth, handleChange, pointerState, localOnChange, scale, trimByLimits, width]);

    const dragBind = useDrag(cb());

    const pointerClick = useCallback((e: { stopPropagation(): void }) => {
      e.stopPropagation();
    }, []);

    const onChangeHandler = useCallback(
      (scaleValue: number) => {
        if (Number.isNaN(scaleValue)) {
          return;
        }
        localOnChange(scaleValue);
        handleChange(scaleValue);
      },
      [handleChange, localOnChange],
    );
    const [inpValue, setInpValue] = useState(`${value}`);

    const keyUpHandler = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (isKeyPressed(e, { key: ['ArrowUp', 'ArrowDown'] })) {
          e.preventDefault();
          const v = e.key === 'ArrowDown' ? step * -1 : step;
          localOnChange(pointerState + v);
          setInpValue(`${pointerState + v}`);
        }
      },
      [localOnChange, pointerState, setInpValue, step],
    );

    const pointerPercent = useMemo(() => {
      return (pointerState / scale) * 100;
    }, [pointerState, scale]);

    useEffect(() => {
      setInpValue(`${innerValue}`);
    }, [innerValue, setInpValue]);

    const isExceededMax = useMemo(() => {
      return parseFloat(inpValue) > max;
    }, [inpValue, max]);

    const handleChangeInput = useCallback(
      (v: string) => {
        const rs = `^\\d*[.]{0,1}\\d{0,${countStepDecimals}}$`;
        const r = new RegExp(rs);

        if (!r.test(v)) {
          return;
        }

        setInpValue(v);

        const prepValue = parseFloat(v) - localMin;
        if (prepValue > max) {
          exceedMaxFn?.();
          return;
        }

        onChangeHandler(prepValue);
      },
      [exceedMaxFn, max, onChangeHandler, countStepDecimals],
    );

    useEffect(() => {
      if (isEqual(value, innerValue)) return;
      setInnerValue(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onClickScale = useCallback(
      (e: { clientX: number }) => {
        if (width === null) {
          calcWidth();
        }

        if (scaleRef.current && pointerRef.current && width !== null) {
          const { left } = scaleRef.current.getBoundingClientRect();
          const pointerSize = pointerRef.current.offsetWidth;
          const position = Math.max(Math.min(e.clientX - left - pointerSize / 2, width), 0);
          const newValue = (position / width) * scale;

          localOnChange(newValue);
          handleChange(newValue);
        }
      },
      [calcWidth, handleChange, localOnChange, scale, width],
    );

    const handleBlurInput = useCallback(() => {
      setInpValue(`${innerValue}`);
      handleChange(innerValue, true);
    }, [handleChange, innerValue, setInpValue]);

    return (
      <div className={cn(className, styles.root)}>
        {label !== false && (
          <Text
            className={styles.label}
            variant="control"
            size="lg"
          >
            {label}
          </Text>
        )}
        <div className={styles.controlPanel}>
          <Text>0</Text>
          <Input
            name="points"
            value={`${inpValue}`}
            label={false}
            size="md"
            isClearable={false}
            onChange={handleChangeInput}
            staticNote={false}
            ariaLabel="Current value"
            className={styles.input}
            onKeyUp={keyUpHandler}
            onBlur={handleBlurInput}
          />
          <Text>{maxMsg ?? max}</Text>
        </div>
        <div
          className={styles.scale}
          ref={scaleRef}
          onClick={onClickScale}
        >
          <div
            className={styles.track}
            ref={trackRef}
          >
            <div
              className={cn([styles.scaleFilled, dragging && styles._noTransition])}
              style={{
                width: `${pointerPercent}%`,
              }}
            />
            <div
              ref={pointerRef}
              tabIndex={0}
              className={cn([styles.pointer, dragging && styles._noTransition])}
              onClick={pointerClick}
              {...dragBind()}
              style={{
                left: `${pointerPercent}%`,
              }}
            />
          </div>
        </div>
        {isExceededMax && (
          <Text
            mt="xs"
            className={styles.error}
          >
            Entered amount exceeds available points
          </Text>
        )}
      </div>
    );
  },
);

export default Slider;
export { Slider };
