import { SnackbarService } from '@swe/shared/providers/snackbar';
import { useIntl } from '@swe/shared/tools/intl';
import { useBreakpoint } from '@swe/shared/tools/media';
import { Alert } from '@swe/shared/ui-kit/components/alert';
import { ButtonColor } from '@swe/shared/ui-kit/components/button';
import { Divider } from '@swe/shared/ui-kit/components/divider';
import { Form } from '@swe/shared/ui-kit/components/form';
import { BuilderContext } from '@swe/shared/ui-kit/components/form/core/builder';

import { Grid } from '@swe/shared/ui-kit/components/grid';
import { EditIcon, ClockIcon, CarDeliveryIcon, ShopIcon, InfoIcon } from '@swe/shared/ui-kit/components/icon';
import { Portal } from '@swe/shared/ui-kit/components/portal';
import { Price } from '@swe/shared/ui-kit/components/price';
import { Stack } from '@swe/shared/ui-kit/components/stack';
import SVG from '@swe/shared/ui-kit/components/svg';
import { PatternThemeProvider, useTheme } from '@swe/shared/ui-kit/theme/provider';
import cn from 'clsx';

import React, { ReactNode, useEffect, useMemo, useRef } from 'react';

import AeroPayLogo from './assets/aero-pay-logo.svg';

import styles from './styles.module.scss';

import { getAlertMessage } from './utils';

import { FixedFooterBox } from 'common/components/fixed-footer-box';
import { getPrepAnalyticsCartItems } from 'common/entities/product-analitycs';
import { useAnalytics } from 'common/providers/analytics';
import { AEventType } from 'common/providers/analytics/constants';
import { useCart } from 'common/providers/cart';
import { useStoreConfig } from 'common/providers/config';
import { useGuest } from 'common/providers/guest';
import { usePickupDeliveryCtx } from 'common/providers/pickup-delivery';
import { useCurrentUser } from 'common/providers/user';
import { useMichigan } from 'common/use-cases/use-michigan';
import { getScheduleNames } from 'common/utils/schedule-names';
import { FormDocuments } from 'domains/checkout/components/form-documents';
import { FormErrors } from 'domains/checkout/components/form-errors';
import { FormFulfillment } from 'domains/checkout/components/form-fulfillment';
import { FormLoyalty } from 'domains/checkout/components/form-loyalty';
import { FormPromoCodes } from 'domains/checkout/components/form-promo-codes';
import { FormTextarea } from 'domains/checkout/components/form-textarea';
import { SectionList } from 'domains/checkout/components/section-list';
import { PlaceOrderSkeleton } from 'domains/checkout/components/skeleton';
import { Summary } from 'domains/checkout/components/summary';
import { PromoCodesModalForm } from 'domains/checkout/containers/promo-codes-modal';
import { PlaceOrderForm, useCheckout } from 'domains/checkout/use-cases/use-checkout';

import { PaymentMethod } from 'entities/common/orders';
import { ShopFulfillmentType } from 'entities/shop/info';

type PlaceOrderButtonProps = {
  disabled?: boolean;
  isAeroPaySelected?: boolean;
  pending?: boolean;
};

const PlaceOrderButton = ({ disabled, isAeroPaySelected, pending }: PlaceOrderButtonProps) => {
  const theme = useTheme();
  return (
    <PatternThemeProvider name="order-summary">
      <Form.SubmitButton
        block
        disabled={disabled}
        pending={pending}
        color={theme.orderSummary.button.style.purchase as ButtonColor}
        name="place-order"
      >
        <div className={styles.placeOrderBtn}>
          {isAeroPaySelected ? 'Proceed with' : 'Place Order'}
          {isAeroPaySelected && (
            <SVG
              src={AeroPayLogo}
              className={styles.aeroPayIcon}
            />
          )}
        </div>
      </Form.SubmitButton>
    </PatternThemeProvider>
  );
};

const Checkout = () => {
  const { desktop, mobile } = useBreakpoint();
  const { user } = useCurrentUser();
  const { intl } = useIntl();

  const { open } = usePickupDeliveryCtx();
  const formRef = useRef<BuilderContext<PlaceOrderForm>>(null);

  const {
    promocodeProps,
    fulfillmentDateProps,
    fulfillmentIntervalProps,
    formInitialValues,
    prepareListMarker,
    fulfillmentProps,
    paymentMethodProps,
    receipt,
    isLoading,
    orderType,
    formSchema,
    completeOrder,
    formOnChange,
    loyaltyProps,
    isEnabledDocuments,
    goToDocuments,
    notesProps,
    alertMessage,
    isDisabled,
    isCheckoutConsentsEnabled,
    isPaymentsDisabled,
    offsetAlert,
    documentsProps,
    isSubmitting,
    formIsDisabled,
    setFormErrors,
    isPickupDeliveryAvailable,
    documentsRequiredNames,
    amountLeftForFreeDelivery,
    isEnabledDeliveryDateInterval,
  } = useCheckout({ formRef });
  const cart = useCart();

  const { pushEvent } = useAnalytics();

  const isSent = useRef(false);
  useEffect(() => {
    if (!cart || isSent.current) {
      return;
    }
    pushEvent(AEventType.BEGIN_CHECKOUT, getPrepAnalyticsCartItems(cart, user));
    isSent.current = true;
  }, [cart, pushEvent, user]);

  const { placeOrderDisclaimer, storeInfo } = useMichigan();
  const { availableFulfillment, options } = fulfillmentProps;

  const alertElement = useMemo(() => {
    if (fulfillmentDateProps.options.length) {
      return null;
    }

    const alertFulfilledMessage = getAlertMessage(availableFulfillment, options, formInitialValues.fulfillmentType);
    const shopIcon = () => <ShopIcon className={styles.snackAlertSvg} />;
    return (
      <Grid.Cell cols={{ desktop: 12, tablet: 12, mobile: 12 }}>
        <Alert
          icon={shopIcon}
          color="danger"
          className={styles.snackAlert}
          title={alertFulfilledMessage}
        />
      </Grid.Cell>
    );
  }, [availableFulfillment, options, formInitialValues.fulfillmentType, fulfillmentDateProps.options.length]);
  const paymentMethodType = formRef.current?.getValues()?.paymentMethodType;

  const placeOrderButton = (
    <PlaceOrderButton
      disabled={isDisabled}
      pending={isSubmitting}
      isAeroPaySelected={paymentMethodType === PaymentMethod.AeroPay}
    />
  );

  const hasDeliveryPromos = useMemo(() => Boolean(storeInfo.deliveryPromos?.length), [storeInfo.deliveryPromos]);

  const appliedDeliveryPromoId = useMemo(() => {
    if (!cart?.order?.appliedPromoIds || !storeInfo.deliveryPromos) {
      return undefined;
    }

    return cart.order.appliedPromoIds.find((appliedId) => storeInfo.deliveryPromos?.some(({ id }) => id === appliedId));
  }, [cart?.order?.appliedPromoIds, storeInfo.deliveryPromos]);

  const appliedPromoIdRef = useRef(appliedDeliveryPromoId);

  useEffect(() => {
    // 10 case alert
    if (appliedDeliveryPromoId === appliedPromoIdRef.current) return;

    appliedPromoIdRef.current = appliedDeliveryPromoId;

    const shouldShowSnackbar =
      (hasDeliveryPromos && orderType === ShopFulfillmentType.Delivery) || orderType === ShopFulfillmentType.Pickup;

    if (shouldShowSnackbar) {
      SnackbarService.push({
        type: 'success',
        message: 'Your changes have adjusted the total price of items in your cart',
      });
    }
  }, [hasDeliveryPromos, appliedDeliveryPromoId, orderType]);

  const deliveryAlertElement = useMemo(() => {
    if (!storeInfo.deliveryPromos) return null;
    const scheduleNames = getScheduleNames(storeInfo.deliveryPromos);

    return (
      <Alert
        size="md"
        color="neutral"
        title="Delivery deal available"
        icon={CarDeliveryIcon}
        iconEnd={{
          icon: InfoIcon,
          tooltip: [
            'Delivery deals are available on the following days:',
            ...scheduleNames,
            'Deals will be applied when the conditions and stacking rules are met.',
          ],
        }}
      />
    );
  }, [storeInfo.deliveryPromos]);

  const childrenOptions = useMemo(
    () => (
      <Grid.Row className={cn(styles.sectionContent, styles.sectionFulfillmentInterval)}>
        {fulfillmentDateProps.options.length ? (
          <>
            <Grid.Cell cols={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <Form.Select
                size={mobile ? 'md' : 'lg'}
                label={`${orderType === 'Pickup' ? 'Pickup' : 'Delivery'} Date`}
                placeholder="Choose time window"
                staticNote={false}
                {...fulfillmentDateProps}
              />
            </Grid.Cell>
            <Grid.Cell cols={{ desktop: 6, tablet: 6, mobile: 12 }}>
              <Form.Select
                size={mobile ? 'md' : 'lg'}
                label={`${orderType === 'Pickup' ? 'Pickup' : 'Delivery'} Time Window`}
                placeholder="Choose time window"
                staticNote={false}
                {...fulfillmentIntervalProps}
              />
            </Grid.Cell>
            {/* 9 case  Place order page delivery */}
            {orderType === ShopFulfillmentType.Delivery && hasDeliveryPromos && !appliedDeliveryPromoId && (
              <Grid.Cell>{deliveryAlertElement}</Grid.Cell>
            )}
            {offsetAlert.diffHours !== 0 && (
              <Grid.Cell cols={12}>
                <Alert
                  icon={ClockIcon}
                  color="neutral"
                  title={`Date and time are local (${offsetAlert.timezoneName})`}
                />
              </Grid.Cell>
            )}
          </>
        ) : (
          alertElement
        )}
      </Grid.Row>
    ),
    [
      fulfillmentDateProps,
      fulfillmentIntervalProps,
      mobile,
      offsetAlert.diffHours,
      offsetAlert.timezoneName,
      orderType,
      alertElement,
      hasDeliveryPromos,
      appliedDeliveryPromoId,
      deliveryAlertElement,
    ],
  );

  const childrenPaymentMethod = useMemo(
    () => (
      <Form.RadioGroup
        className={styles.sectionContent}
        variant="radio"
        // @ts-expect-error RadioGroupVariantRadioProps
        inline
        spacing={mobile ? 'sm' : 'xxl'}
        {...paymentMethodProps}
      />
    ),
    [mobile, paymentMethodProps],
  );

  const childrenDelivery = useMemo(
    () => (
      <>
        <FormFulfillment
          className={cn(styles.sectionFulfillment, styles.sectionContent)}
          {...fulfillmentProps}
        />
        {/* 8 case  Place order page pickup */}
        {orderType === ShopFulfillmentType.Pickup && hasDeliveryPromos && (
          <div className={styles.sectionContent}>{deliveryAlertElement}</div>
        )}
        {!!placeOrderDisclaimer && <div className={styles.sectionContent}>{placeOrderDisclaimer}</div>}
        {!!amountLeftForFreeDelivery && amountLeftForFreeDelivery > 0 && (
          <div className={styles.sectionContent}>
            <Alert
              color="neutral"
              icon={CarDeliveryIcon}
            >
              Add <Price value={amountLeftForFreeDelivery} /> to get free delivery
            </Alert>
          </div>
        )}
      </>
    ),
    [
      amountLeftForFreeDelivery,
      fulfillmentProps,
      placeOrderDisclaimer,
      orderType,
      hasDeliveryPromos,
      deliveryAlertElement,
    ],
  );

  const childrenDocuments = useMemo(
    () => (
      <FormDocuments
        className={styles.sectionContent}
        {...documentsProps}
      />
    ),
    [documentsProps],
  );

  const { isGuest, isLoading: isLoadingGuest } = useGuest();
  const { guestSettings } = useStoreConfig();

  const list = useMemo(() => {
    type ListItem = {
      headerAction?: any;
      title: string;
      fields: (keyof PlaceOrderForm)[];
      children: ReactNode;
      subHeading?: string;
      isDisabled?: boolean;
      headerButton?: any;
    };

    const items: ListItem[] = [
      {
        title: orderType === ShopFulfillmentType.Pickup ? 'Pickup at' : 'Delivery Address',
        fields: ['fulfillmentType'],
        headerAction:
          isPickupDeliveryAvailable && ((!isLoadingGuest && !isGuest) || guestSettings?.isMedicalGuest)
            ? {
                icon: EditIcon,
                onClick: open,
              }
            : undefined,
        children: childrenDelivery,
      },
      {
        title: 'Documents',
        fields: ['documentsIsValid'],
        subHeading: formRef.current?.getValues('documentsIsValid')
          ? 'Documents have been attached'
          : documentsRequiredNames,
        ...(formRef.current?.getValues('documentsIsValid')
          ? {}
          : {
              headerButton: {
                children: user?.isActionRequired ? 'Action required' : 'Upload',
                onClick: () => goToDocuments(),
                name: 'upload',
                color: undefined,
              },
            }),
        children: childrenDocuments,
        isDisabled: !isEnabledDocuments,
      },
      {
        title: 'Options',
        fields: ['fulfillmentIntervalId', 'fulfillmentDate'],
        children: childrenOptions,
        isDisabled: !isEnabledDeliveryDateInterval,
      },
      {
        title: 'Payment Method',
        fields: ['paymentMethodType'],
        children: childrenPaymentMethod,
        isDisabled: isPaymentsDisabled,
      },
    ];
    return items.filter(({ isDisabled }) => !isDisabled).map(prepareListMarker);
  }, [
    orderType,
    isPickupDeliveryAvailable,
    isLoadingGuest,
    isGuest,
    guestSettings?.isMedicalGuest,
    open,
    childrenDelivery,
    documentsRequiredNames,
    user?.isActionRequired,
    childrenDocuments,
    isEnabledDocuments,
    childrenOptions,
    isEnabledDeliveryDateInterval,
    childrenPaymentMethod,
    isPaymentsDisabled,
    prepareListMarker,
    goToDocuments,
  ]);

  return (
    <>
      <Stack
        mt="lg"
        className={cn(!isLoading && styles._hidden)}
      >
        <PlaceOrderSkeleton />
      </Stack>
      <Form.Builder<PlaceOrderForm>
        className={cn(isLoading && styles._hidden)}
        initialValues={formInitialValues}
        validationSchema={formSchema}
        ref={formRef}
        onChange={formOnChange}
        onSubmit={completeOrder}
        autofocus={false}
        alwaysEmitOnChange
        name="place-order"
        disabled={formIsDisabled}
      >
        <FormErrors onError={setFormErrors} />
        <Grid.Row
          spacing={{
            desktop: 'xxxxl',
            tablet: 'sm',
            mobile: 'md',
          }}
          className={styles.formCont}
        >
          <Grid.Cell
            className={cn(mobile && styles.trimFormPadding)}
            cols={{ desktop: 8, tablet: 12, mobile: 12 }}
          >
            <Stack
              spacing={{
                mobile: 'md',
                tablet: 'lg',
                desktop: 'lg',
              }}
            >
              <SectionList
                list={list}
                size={mobile ? 'md' : 'lg'}
                divider
              />
              <Divider />
              <FormTextarea
                size="lg"
                label="Order Notes"
                maxLength={255}
                {...notesProps}
                placeholder={
                  orderType === ShopFulfillmentType.Delivery
                    ? 'Add details or instructions for your delivery driver. This can include directions on how to locate you, your preferred contact method, or any other necessary delivery details.'
                    : 'Leave a note for the store about your order'
                }
                // placeholder="Optional: Leave a note for the store about your order"
              />
            </Stack>
          </Grid.Cell>
          {mobile && (
            <Grid.Cell cols={12}>
              <Divider />
            </Grid.Cell>
          )}
          <Grid.Cell cols={{ desktop: 4, tablet: 12, mobile: 12 }}>
            <Stack>
              <Summary
                receipt={receipt}
                footer={
                  <Stack spacing="md">
                    {isCheckoutConsentsEnabled && (
                      <Form.Checkbox
                        name="consents"
                        label={intl.t(
                          'domains.checkout.checkoutConsents.checkboxLabel',
                          undefined,
                          'Please understand that all orders and product requests are subject to availability upon arrival.',
                        )}
                      />
                    )}
                    {desktop && placeOrderButton}
                  </Stack>
                }
                promoCodes={
                  !isLoadingGuest && !isGuest ? (
                    <FormPromoCodes
                      link={!promocodeProps.isPatientOrder ? <PromoCodesModalForm {...promocodeProps} /> : undefined}
                      {...promocodeProps}
                    />
                  ) : null
                }
                loyalty={loyaltyProps ? <FormLoyalty {...loyaltyProps} /> : null}
                alertMessage={alertMessage}
              />
              {!desktop && (
                <Portal name="fixedFooter">
                  <FixedFooterBox>{placeOrderButton}</FixedFooterBox>
                </Portal>
              )}
            </Stack>
          </Grid.Cell>
        </Grid.Row>
      </Form.Builder>
    </>
  );
};

export { Checkout };
export default Checkout;
