import { Caption } from '@swe/shared/ui-kit/components/form/caption';
import { withFormField } from '@swe/shared/ui-kit/components/form/core';
import { FormControl } from '@swe/shared/ui-kit/components/form/types';

import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

export type DocumentsProps = FormControl<boolean> & ComponentHasClassName;

const Documents = ({ error, className }: DocumentsProps) => {
  return (
    <Caption
      className={className}
      animated
      visible={!!error}
      variant="error"
    >
      {error}
    </Caption>
  );
};

const FormDocuments = withFormField<boolean, DocumentsProps>(Documents, { passRef: false });

export { FormDocuments };
export default FormDocuments;
