import { useToggleable } from '@swe/shared/hooks/use-toggleable';
import { withFormField } from '@swe/shared/ui-kit/components/form/core';
import { FormControl, FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { Link } from '@swe/shared/ui-kit/components/link';
import { Modal } from '@swe/shared/ui-kit/components/modal';

import { Text } from '@swe/shared/ui-kit/components/text';

import { ForwardedRef, forwardRef, useCallback, useImperativeHandle } from 'react';

import { PromoCodeList } from 'common/containers/promocode-list';
import { useCurrentUser } from 'common/providers/user';
import { GetPromocodeListEndpoint } from 'endpoints/profile/promocodes/get-list';

type PromoCodesModalProps = FormControl<string[]> & { pending?: boolean };

const PromoCodesModal = ({ onChange, value, pending }: PromoCodesModalProps, ref: ForwardedRef<FormControlRef>) => {
  useImperativeHandle(ref, () => ({
    focus: () => {},
  }));
  const { isLoggedIn } = useCurrentUser();
  const { data: promoCodeData } = GetPromocodeListEndpoint.useRequest(isLoggedIn ? undefined : null);
  const countPromoCodes = promoCodeData?.length ?? 0;
  const [isOpen, , close, toggle] = useToggleable();

  const onToggle = useCallback(
    (code: string) => {
      let res: string[] | undefined;

      if (value.includes(code)) {
        res = value.filter((innerCode) => innerCode !== code);
      } else {
        close();
        res = [...value, code];
      }
      onChange?.(res);
    },
    [close, onChange, value],
  );

  return (
    <>
      {countPromoCodes > 0 && (
        <Link onClick={toggle}>
          <Text
            variant="body"
            size="md"
          >
            You have {countPromoCodes} promocodes
          </Text>
        </Link>
      )}
      <Modal
        ariaLabel="Promo Codes"
        heading="Promo Codes"
        visible={isOpen}
        onClose={close}
        size="lg"
      >
        <PromoCodeList
          onToggle={onToggle}
          appliedCodes={value}
          pending={pending}
        />
      </Modal>
    </>
  );
};

const _PromoCodesModalForm = forwardRef(PromoCodesModal) as (
  props: PromoCodesModalProps,
) => ReturnType<typeof PromoCodesModal>;

const PromoCodesModalForm = withFormField<string[]>(_PromoCodesModalForm);

export { PromoCodesModal, PromoCodesModalForm };
