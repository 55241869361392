import { useDeepCompareEffect } from '@swe/shared/hooks/use-deep-compare';
import { useFormContext } from '@swe/shared/ui-kit/components/form';

type FormErrorsProps = {
  onError: (errors: object) => void;
};

const FormErrors = ({ onError }: FormErrorsProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  useDeepCompareEffect(() => {
    onError(errors);
  }, [errors]);
  return null;
};

export { FormErrors };
