import { FullShopFulfillmentType, ShopFulfillmentType } from 'entities/shop/info';

type ShopFulfillmentOptions = {
  value: FullShopFulfillmentType;
  label: string;
};

export const getAlertMessage = (
  shopFulfillmentTypes: ShopFulfillmentType[],
  shopFulfillmentOptions: ShopFulfillmentOptions[],
  subTypePickupName?: string,
): string => {
  if (subTypePickupName === ShopFulfillmentType.Delivery) {
    return `${subTypePickupName} slots are all taken. Choose pickup instead.`;
  }

  const isDeliveryAvailable = shopFulfillmentTypes.includes(ShopFulfillmentType.Delivery);
  const displayingName = shopFulfillmentOptions.find(({ value }) => value === subTypePickupName)?.label || '';

  const pickupSlotsTakenMessage = `${displayingName} slots are taken.`;
  const chooseAnotherOptionMessage = 'Choose another option';
  const scheduleDeliveryMessage = 'Schedule a delivery instead.';
  const tryAgainLaterMessage = 'Please try again later.';

  if (shopFulfillmentOptions.length > 1) {
    if (isDeliveryAvailable) {
      return `${pickupSlotsTakenMessage} ${chooseAnotherOptionMessage} or ${scheduleDeliveryMessage.toLocaleLowerCase()}`;
    }
    return `${pickupSlotsTakenMessage} ${chooseAnotherOptionMessage}.`;
  }
  if (isDeliveryAvailable) {
    return `${pickupSlotsTakenMessage} ${scheduleDeliveryMessage}`;
  }
  return `${pickupSlotsTakenMessage} ${tryAgainLaterMessage}`;
};
