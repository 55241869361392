import withFormField from '@swe/shared/ui-kit/components/form/core/field';
import { Textarea, TextareaProps } from '@swe/shared/ui-kit/components/form/textarea';
import { FormControlRef } from '@swe/shared/ui-kit/components/form/types';
import { forwardRef, useCallback, useEffect, useState } from 'react';

const CustomTextarea = forwardRef<FormControlRef, TextareaProps>(({ onChange, value, ...props }, ref) => {
  const [_value, setValue] = useState(value);
  const onBlur = useCallback(() => {
    onChange?.(_value);
  }, [_value, onChange]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <Textarea
      ref={ref}
      {...props}
      value={_value}
      onChange={setValue}
      onBlur={onBlur}
    />
  );
});

const FormTextarea = withFormField<string, TextareaProps>(CustomTextarea);

export { FormTextarea };
