import Skeleton, { Bone } from '@swe/shared/ui-kit/components/skeleton';

const PlaceOrderSkeleton = () => (
  <Skeleton
    uniqueKey="place_order"
    height={700}
  >
    <Bone
      x="0"
      y="0"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <Bone
      x="850"
      y="0"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <Bone
      x="56"
      y="8"
      rx="3"
      ry="3"
      width="120"
      height="28"
    />
    <Bone
      x="56"
      y="42"
      rx="3"
      ry="3"
      width="240"
      height="24"
    />
    <Bone
      x="56"
      y="72"
      rx="3"
      ry="3"
      width="500"
      height="24"
    />
    <Bone
      x="0"
      y="128"
      rx="50%"
      ry="50%"
      width="40"
      height="40"
    />
    <Bone
      x="820"
      y="128"
      rx="20"
      ry="20"
      width="70"
      height="40"
    />
    <Bone
      x="56"
      y="136"
      rx="3"
      ry="3"
      width="120"
      height="28"
    />
    <Bone
      x="56"
      y="170"
      rx="3"
      ry="3"
      width="240"
      height="24"
    />
    <Bone
      x="0"
      y="226"
      rx="20"
      ry="20"
      width="40"
      height="40"
    />
    <Bone
      x="56"
      y="232"
      rx="3"
      ry="3"
      width="100"
      height="28"
    />
    <Bone
      x="56"
      y="270"
      rx="3"
      ry="3"
      width="120"
      height="20"
    />
    <Bone
      x="56"
      y="296"
      rx="12"
      ry="12"
      width="400"
      height="56"
    />
    <Bone
      x="472"
      y="270"
      rx="3"
      ry="3"
      width="120"
      height="20"
    />
    <Bone
      x="472"
      y="296"
      rx="12"
      ry="12"
      width="400"
      height="56"
    />

    <Bone
      x="0"
      y="390"
      rx="20"
      ry="20"
      width="40"
      height="40"
    />
    <Bone
      x="56"
      y="390"
      rx="3"
      ry="3"
      width="120"
      height="28"
    />
    <Bone
      x="56"
      y="440"
      rx="12"
      ry="12"
      width="120"
      height="28"
    />
    <Bone
      x="224"
      y="440"
      rx="12"
      ry="12"
      width="120"
      height="28"
    />
    <Bone
      x="392"
      y="440"
      rx="12"
      ry="12"
      width="120"
      height="28"
    />
    <Bone
      x="0"
      y="500"
      rx="12"
      ry="12"
      width="900"
      height="1"
    />
    <Bone
      x="0"
      y="532"
      rx="3"
      ry="3"
      width="120"
      height="20"
    />
    <Bone
      x="0"
      y="558"
      rx="12"
      ry="12"
      width="900"
      height="80"
    />
    <Bone
      x="954"
      y="0"
      rx="24"
      ry="24"
      width="400"
      height="80"
    />
  </Skeleton>
);

export { PlaceOrderSkeleton };
export default PlaceOrderSkeleton;
